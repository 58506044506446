<template>
    <div>
        <div class="row">
            <div class="col-md-9 col-12">
                <nav class="navbar navbar-expand-lg navbar-light bg-light">
                    <b-form-select v-model="selectedTeam" :options="teams2" style="width: 300px;"
                                   @change="statusChange"></b-form-select>
                    <b-form-select v-model="selectedStatus" :options="statusArray" style="width: 250px;"
                                   @change="statusChange"></b-form-select>
                    <div class="mr-auto"></div>
                    <form class="form-inline" @submit.prevent="search">
                        <div class="form-group ml-1 my-2 my-sm-0">
                            <datepicker v-model="shot_date" :disabledDates="datePicker.disabledDates" bootstrap-styling
                                        calendar-button style="width: 230px" clear-button
                                        :calendar-button-icon="datePicker.icon" :language="datePicker.language"
                                        :format="datePicker.format"></datepicker>
                        </div>
                        <div class="form-group ml-1 my-2 my-sm-0">
                            <input class="form-control" type="search" placeholder="キーワード" v-model="keyword"
                                   aria-label="キーワード" style="width: 15rem;">
                        </div>
                        <button class="btn btn-outline-success ml-1 my-2 my-sm-0" type="submit"><i
                            class="icon ion-md-search"></i> 検索
                        </button>
                    </form>
                </nav>
                <b-table selectable striped hover :items="videos" :fields="fields" :current-page="currentPage"
                         primary-key="vid"
                         :per-page="perPage" bordered ref="table" @row-selected="rowSelected" @refresh="tableRefresh"
                         :sort-by.sync="sortBy" no-local-sorting no-sort-reset
                         :sort-desc.sync="sortDesc" select-mode="single" show-empty empty-text="結果ありません">
                    <template v-slot:cell(name)="data">
                        <img :src="data.item.thumb?data.item.thumb:inProgress" alt=""
                             class="img-thumbnail thumb float-left mr-3">
                        <div>{{ data.value }}</div>
                        <small class="text-muted">{{ data.item.description }}</small>
                        <b-badge pill variant="secondary" class="float-right"
                                 v-if="!data.item.deleted && data.item.archived">アーカイブ
                        </b-badge>
                        <b-badge pill variant="warning" class="float-right" v-if="data.item.deleted">削除予定</b-badge>
                        <b-badge pill :variant="statusVariant[data.item.status]" class="float-right"
                                 v-if="!data.item.deleted && !data.item.archived">{{ status[data.item.status] }}
                        </b-badge>
                    </template>
                    <template v-slot:cell(created)="data">
                        <div>{{ $moment(data.item.created).local().calendar() }}</div>
                    </template>
                </b-table>
                <b-row>
                    <b-col md="6" class="my-1">
                        <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="my-0"/>
                    </b-col>
                    <b-col md="6" class="my-1 text-right">
                        <span>{{ currentRows }} / {{ totalRows }}</span>
                    </b-col>
                </b-row>
            </div>
            <div class="col-md-3 d-none d-md-block">
                <div class="card info-card" id="info-card">
                    <div class="card-img-top thumbLarge" v-if="selectedItem && selectedItem.status === 'ready'"
                         @click.prevent="playVideo"
                         :style="{ cursor: 'pointer', 'backgroundImage': 'url(' + (selectedItem.poster?selectedItem.poster:inProgress) + ')' }">
                        <img src="@/assets/img/play-button.png" alt="再生" class="playbutton"
                             v-if="!selectedItem.archived">
                    </div>
                    <div class="card-img-top thumbLarge"
                         v-if="selectedItem && selectedItem.status !== 'ready' && !selectedItem.archived"
                         :style="{backgroundImage: 'url('+inProgress+')'}">
                    </div>
                    <div class="card-body" v-if="selectedItem">
                        <h5 class="card-title" v-html="selectedItem.name"></h5>
                        <div class="card-text mb-3 small">
                            <div>
                                <span class="font-weight-bold">VID：</span><span class="text-info"
                                                                                v-html="selectedItem.video_id"></span>
                            </div>
                            <div>
                                <span class="font-weight-bold">詳細：</span><span class="text-info"
                                                                               v-html="selectedItem.description"></span>
                            </div>
                            <div>
                                <span class="font-weight-bold">日付：</span><span class="text-info"
                                                                               v-html="selectedItem.shot_date"></span>
                            </div>
                            <div>
                                <span class="font-weight-bold">チーム：</span><span class="text-info"
                                                                                v-html="selectedItem.group"></span>
                            </div>
                            <div>
                                <span class="font-weight-bold">ENC ID：</span><span class="text-info"
                                                                                   v-html="selectedItem.enc_id"></span>
                            </div>
                            <div>
                                <span class="font-weight-bold">パース：</span><span class="text-info"
                                                                                v-html="selectedItem.path"></span>
                            </div>
                            <div>
                                <span class="font-weight-bold">MD5：</span><span class="text-info"
                                                                                v-html="selectedItem.hash"></span>
                            </div>
                        </div>
                    </div>
                    <b-button-group class="no-round" v-if="selectedItem && selectedItem.status === 'ready'">
                        <!--
                        <b-button variant="warning" @click="editVideo">編集</b-button>
                        <b-button variant="danger" @click="deleteVideo" v-if="!selectedItem.deleted">削除</b-button>
                        -->
                        <b-button variant="success" @click="showUpload" v-if="selectedItem.upload">アップロードを確認</b-button>
                        <b-button variant="danger" @click="cancelDeleteVideo" v-if="selectedItem.deleted">削除を取り消し
                        </b-button>
                        <b-button variant="secondary"
                                  v-if="!selectedItem.archived && !selectedItem.deleted"
                                  @click="archive">アーカイブ
                        </b-button>
                        <b-button variant="primary"
                                  v-if="selectedItem.archived && !selectedItem.deleted"
                                  @click="restore">復元
                        </b-button>
                        <b-button variant="info"
                                  v-if="!selectedItem.archived && !selectedItem.deleted"
                                  @click="openAcl">閲覧権限
                        </b-button>
                    </b-button-group>
                    <b-button-group class="no-round" v-if="selectedItem && selectedItem.status !== 'ready'">
                        <!--
                        <b-button variant="warning" @click="editVideo">編集</b-button>
                        <b-button variant="danger" @click="deleteVideo" v-if="!selectedItem.deleted">削除</b-button>
                        -->
                        <b-button variant="success" @click="showUpload">アップロードを確認</b-button>
                        <b-button variant="primary" v-if="selectedItem.status === 'enc_err'" @click="reSchedule">
                            再エンコード
                        </b-button>
                        <b-button variant="info"
                                  @click="openAcl">閲覧権限
                        </b-button>
                    </b-button-group>
                </div>
            </div>
        </div>
        <b-modal id="modalUploadList" title="アップロードリスト" size="lg" ref="uploadModalList" @shown="modalOpen" hide-footer>
            <div style="max-height: 600px; overflow-y: auto">
                <b-list-group>
                    <b-list-group-item v-for="upload in uploads" :key="upload.upload_id">
                        <b-badge pill :variant="statusVariant[upload.status]" class="float-right">
                            {{ status[upload.status] }}
                        </b-badge>
                        {{ upload.path }}<br>
                        <small class="text-muted">{{ filesize(upload.size) }}</small>
                    </b-list-group-item>
                </b-list-group>
            </div>
        </b-modal>
        <b-modal id="modalAcl" title="閲覧権限" size="md" ref="aclModal" hide-footer>
            <div style="max-height: 600px; overflow-y: auto">
                <b-form-group label="閲覧権限" readonly>
                    <b-form-checkbox-group
                        v-model="acl"
                        :options="teams"
                        readonly
                        disabled
                        stacked
                    ></b-form-checkbox-group>
                </b-form-group>
            </div>
        </b-modal>
    </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import {ja} from 'vuejs-datepicker/dist/locale'
import filesize from 'filesize'
import inProgress from '@/assets/img/inprogress.svg'

export default {
    name: 'Videos',
    components: {
        Datepicker
    },
    props: {
        teams: Array,
        teams2: Array
    },
    data() {
        return {
            inProgress,
            selectedStatus: 'all',
            acl: [],
            filesize,
            shot_date: '',
            keyword: '',
            totalRows: 0,
            currentPage: 1,
            perPage: 50,
            currentRows: '',
            sortBy: 'created',
            sortDesc: true,
            fields: [
                {
                    key: 'name',
                    label: '映像',
                    sortable: false
                },
                {
                    key: 'shot_date',
                    sortable: true,
                    label: '撮影日付',
                    thClass: 'date'
                },
                {
                    key: 'created',
                    sortable: true,
                    label: '追加日付',
                    thClass: 'date'
                },
                {
                    key: 'group',
                    sortable: false,
                    label: 'チーム',
                    thClass: 'group'
                }
            ],
            selectedItem: null,
            selectedTeam: null,
            uploads: [],
            datePicker: {
                language: ja,
                format: 'yyyy-MM-dd',
                disabledDates: {
                    from: this.$moment().add(1, 'd').toDate()
                },
                icon: 'icon ion-md-calendar'
            },
            status: {
                all: 'すべて',
                init: 'アップロード待ち',
                up: 'アップロード中',
                up_end: '準備中',
                up_err: 'アップロードエラー',
                enc: '準備中',
                enc_err: '準備エラー',
                ready: '利用可能'
            },
            statusVariant: {
                init: 'light',
                up: 'secondary',
                up_end: 'info',
                up_err: 'danger',
                enc: 'dark',
                enc_err: 'danger',
                ready: 'success'
            }
        }
    },
    computed: {
        statusArray() {
            return Object.keys(this.status).map(k => {
                return {value: k, text: this.status[k]}
            });
        }
    },
    methods: {
        openAcl() {
            if (this.selectedItem) {
                this.acl = this.selectedItem.acl.filter(x => x.streaming).map(x => x.group_id)
                this.$refs.aclModal.show();
            }
        },
        statusChange() {
            this.$refs.table.refresh()
        },
        modalOpen() {
            // TODO
        },
        tableRefresh() {
            // this.selectedItem = null;
        },
        cancelDeleteVideo() {
            this.$http.post('video/' + this.selectedItem.video_id + '?oper=canceldel').then((response) => {
                if (response) {
                    this.$refs.table.refresh()
                }
            })
        },
        archive() {
            if (this.selectedItem) {
                this.$getComponent('Home').archiveVideo(this.selectedItem);
            }
        },
        restore() {
            if (this.selectedItem) {
                this.$swal({
                    title: '映像復元',
                    text: `「${this.selectedItem.name}」を復元しますか？\n以下の空欄に「restore」を入力して、復元ボタンをクリックしてください`,
                    input: 'text',
                    type: 'warning',
                    showCancelButton: true,
                    cancelButtonText: 'キャンセル',
                    confirmButtonText: '復元',
                    inputValidator: (value) => {
                        if (!value || value !== 'restore') {
                            return '空欄に「restore」を入力してください'
                        }
                    }
                }).then((result) => {
                    if (result.value) {
                        this.$getComponent('Home').restore(this.selectedItem);
                    }
                })
            }
        },
        showUpload() {
            this.$http.get('upload/list/' + this.selectedItem.video_id).then((response) => {
                this.uploads = response.data.uploads
                this.$refs.uploadModalList.show();
            }).catch((error) => {
                if (error.response) {
                    if (error.response.data && error.response.data.error) {
                        this.$snotify.error(error.response.data.error);
                    } else {
                        this.$snotify.error('サーバーエラー');
                    }
                }
            })
        },
        search() {
            /* eslint-disable no-undef */
            this.$log( 'search', {'search_term': this.keyword});
            // this.selectedItem = null;
            this.$refs.table.refresh()
        },
        editVideo() {
            if (this.selectedItem) {
                // TODO: this.$root.$emit('openVideoEditModal', this.selectedItem.video_id);
            }
        },
        reSchedule() {
            this.$http.post('video/' + this.selectedItem.video_id + '?oper=reschedule').then((response) => {
                if (response) {
                    this.$refs.table.refresh()
                }
            })
        },
        deleteVideo() {
            if (this.selectedItem) {
                // TODO: this.$getComponent('Home').deleteVideo(this.selectedItem)
            }
        },
        playVideo() {
            // console.log(this.selectedItem)
            this.$emit('play-video', this.selectedItem)
            // this.$getComponent('Home').getPlayURL(this.selectedItem, url => {
            //     this.$root.$emit('openVideo', url, this.selectedItem);
            // })
        },
        rowSelected(item) {
            if (item.length) {
                this.selectedItem = item[0];
                /*
                this.$http.get('video/' + this.selectedItem.video_id).then((r) => {
                  if (r) {
                    this.selectedItem = r.data.video;
                    this.acl = r.data.acl.filter(value => value.streaming).map(value => value.group_id);
                  }
                }).catch((error) => {
                  if (error.response) {
                    if (error.response.data && error.response.data.error) {
                      this.$snotify.error(error.response.data.error);
                    } else {
                      this.$snotify.error('サーバーエラー');
                    }
                  }
                });
                 */
            } else {
                this.selectedItem = null;
            }
        },
        videos(ctx, cb) {
            let params = '?page=' + ctx.currentPage + '&size=' + ctx.perPage + '&status=' + this.selectedStatus + '&date=' +
                (this.shot_date ? this.$moment(this.shot_date).format('YYYY-MM-DD') : '') + '&keyword=' + this.keyword +
                '&sort=' + this.sortBy + '&desc=' + this.sortDesc + (this.selectedTeam ? '&gid=' + this.selectedTeam : '');
            ctx.busy = true;
            this.$http.get('video/' + params).then((response) => {
                ctx.busy = false;
                this.totalRows = response.data.total;
                cb(response.data.videos);
                let first = 0;
                if (response.data.videos.length > 0) {
                    first = ((ctx.currentPage - 1) * ctx.perPage) + 1;
                    first = first + ' - ' + (first + response.data.videos.length - 1)
                } else {
                    if (this.keyword || this.shot_date) {
                        this.$snotify.warning('結果がありません')
                    }
                }
                this.currentRows = first
            }).catch((error) => {
                ctx.busy = false;
                if (error.response) {
                    if (error.response.data && error.response.data.error) {
                        this.$snotify.error(error.response.data.error);
                    } else {
                        this.$snotify.error('サーバーエラー');
                    }
                }
                cb([])
                console.log(error)
            })
        }
    },
    mounted() {
        this.$root.$on('updateTable', () => {
            if (this.$refs.table) {
                this.$refs.table.refresh()
            }
        });
    }
}
</script>

<style scoped>
.no-round .btn {
    border-radius: 0;
}

#info-card {
    min-height: 340px;
    position: sticky;
    top: 70px;
}
</style>
