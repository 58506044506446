<template>
    <b-modal id="modalEditVideo" title="映像情報を編集" :size="$env().VUE_APP_NOSHARE?'md':'lg'" ref="videoEditModal" cancel-title="キャンセル" ok-title="保存"
             @ok="submit" @shown="modalOpen">
        <b-form @submit.prevent ref="videoEditForm">
            <div class="row">
                <div :class="$env().VUE_APP_NOSHARE?'col-12':'col-12 col-md-5'">
                    <b-form-group label="タイトル" label-for="upload-title" description="後で検索できるようなタイトルしてください">
                        <b-form-input type="text" id="upload-title" placeholder="映像のタイトルを入力してください" v-model="form.name"
                                      required></b-form-input>
                    </b-form-group>
                    <b-form-group label="日付" label-for="upload-date" description="撮影日">
                        <datepicker v-model="form.shot_date" :disabledDates="$parent.datePicker.disabledDates"
                                    bootstrap-styling
                                    calendar-button :calendar-button-icon="$parent.datePicker.icon"
                                    :language="$parent.datePicker.language" :format="$parent.datePicker.format"
                                    required></datepicker>
                    </b-form-group>
                    <b-form-group label="詳細" label-for="upload-description" description="映像に対する詳細、メモなど">
                        <b-form-textarea type="text" id="upload-description" placeholder="" v-model="form.description"
                                         rows="2" max-rows="3"></b-form-textarea>
                    </b-form-group>
                </div>
                <div class="col-12 col-md-7" v-if="!$env().VUE_APP_NOSHARE">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col">
                                    <b-form-group :label="'閲覧権限'+aclSelected">
                                        <div style="max-height: 330px; overflow-y: auto;" class="px-1">
                                            <b-form-checkbox-group
                                                id="teams-editor"
                                                name="teams"
                                                value-field="group_id"
                                                text-field="name"
                                                stacked
                                                v-model="acls" :options="teams"/>
                                        </div>
                                    </b-form-group>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <b-btn size="sm" block @click="checkAll" variant="outline-success">すべてをチェック</b-btn>
                                </div>
                                <div class="col">
                                    <b-btn size="sm" block @click="unCheckAll" variant="outline-danger">すべてを外す</b-btn>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col">
                                    <b-form-group :label="'DL権限'+aclDLSelected">
                                        <div style="max-height: 330px; overflow-y: auto;" class="px-1">
                                            <b-form-checkbox-group
                                                id="teams-dl-editor"
                                                name="teams_dl"
                                                value-field="group_id"
                                                text-field="name"
                                                stacked
                                                v-model="acls_dl" :options="teams"/>
                                        </div>
                                    </b-form-group>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <b-btn size="sm" block @click="checkAllDL" variant="outline-success">すべてをチェック</b-btn>
                                </div>
                                <div class="col">
                                    <b-btn size="sm" block @click="unCheckAllDL" variant="outline-danger">すべてを外す</b-btn>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-form>
    </b-modal>
</template>

<script>
import Datepicker from 'vuejs-datepicker';

export default {
    name: 'VideoEditModal',
    components: {
        Datepicker
    },
    props: {
        teams: Array,
        team_id: Number
    },
    data() {
        return {
            video_id: 0,
            form: {},
            acls: [this.team_id],
            acls_dl: [this.team_id]
        }
    },
    computed: {
        aclSelected() {
            return this.acls.length - 1 > 0 ? '（' + (this.acls.length - 1) + '件選択中）' : ''
        },
        aclDLSelected() {
            return this.acls_dl.length - 1 > 0 ? '（' + (this.acls_dl.length - 1) + '件選択中）' : ''
        }
    },
    watch: {
        acls_dl: {
            handler(val) {
                val.forEach(v=>{
                    if (!this.acls.includes(v)) {
                        this.acls.push(v)
                    }
                })
            },
            deep: true
        }
    },
    methods: {
        unCheckAll() {
            this.acls = [this.team_id];
            this.$forceUpdate()
        },
        checkAll() {
            let a = [this.team_id];
            for (let i = 0; i < this.teams.length; i++) {
                a.push(this.teams[i].group_id)
            }
            this.acls = a;
            this.$forceUpdate()
        },
        unCheckAllDL() {
            this.acls_dl = [this.team_id];
            this.$forceUpdate()
        },
        checkAllDL() {
            let a = [this.team_id];
            for (let i = 0; i < this.teams.length; i++) {
                a.push(this.teams[i].group_id)
            }
            this.acls_dl = a;
            this.$forceUpdate()
        },
        modalOpen() {
            //
        },
        submit(e) {
            e.preventDefault();
            if (!this.form.name || !this.form.description || !this.form.shot_date) {
                return;
            }
            let $this = this;
            let data = {
                name: this.form.name,
                description: this.form.description ? this.form.description : '',
                shot_date: this.$moment(this.form.shot_date).format('YYYY-MM-DD'),
                acl: this.acls,
                acl_dl: this.acls_dl
            };
            console.log(data);
            $this.$root.$emit('loading', true);
            this.$http.post('video/' + this.form.video_id, data).then((response) => {
                if (response) {
                    this.$refs.videoEditModal.hide();
                    this.$root.$emit('updateTable');
                    /* eslint-disable no-undef */
                    this.$log( 'edit', {'event_label': data.name});
                }
            }).catch((error) => {
                if (error.response) {
                    switch (error.response.status) {
                        case 401:
                            this.$loggedIn = false;
                            break;
                        case 403:
                            this.$snotify.error('編集する権限がありません');
                            break;
                        default:
                            if (error.response.data && error.response.data.error) {
                                $this.$snotify.error(error.response.data.error);
                            } else {
                                $this.$snotify.error('サーバーエラー');
                            }
                            break;
                    }
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    this.$snotify.error(error.message)
                    console.log(error.config);
                    if (this.$ab) {
                        this.$ab.notify(error);
                    }
                }
            }).finally(() => {
                this.$root.$emit('endSpinner');
            })
        }
    },
    mounted() {
        this.$root.$on('openVideoEditModal', (videoId) => {
            this.$http.get('video/' + videoId).then((r) => {
                if (r) {
                    this.form = r.data.video;
                    this.acls = r.data.acl.filter(value => value.streaming).map(value => value.group_id);
                    this.acls_dl = r.data.acl.filter(value => value.download).map(value => value.group_id);
                    this.video_id = videoId;
                    this.$refs.videoEditModal.show();
                }
            }).catch((error) => {
                if (error.response) {
                    switch (error.response.status) {
                        case 401:
                            // this.$loggedIn = false;
                            break;
                        case 403:
                            this.$snotify.error('編集する権限がありません');
                            break;
                        default:
                            if (error.response.data && error.response.data.error) {
                                this.$snotify.error(error.response.data.error);
                            } else {
                                this.$snotify.error('サーバーエラー');
                            }
                            break;
                    }
                } else {
                    if (this.$ab) {
                        this.$ab.notify(error);
                    }
                }
            })
        });
    }
}
</script>

<style scoped>

</style>
