// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import Storage from 'vue-ls';
import App from './App'
import router from './router';
import axios from 'axios';
import vmodal from 'vue-js-modal';
import VueSweetalert2 from 'vue-sweetalert2';
import moment from 'moment/dist/moment'
require('moment/dist/locale/ja')
import vueMoment from 'vue-moment'
import MobileDetect from 'mobile-detect'
import {Notifier} from '@airbrake/browser'

import '@/assets/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './registerServiceWorker'
import mixpanel from 'mixpanel-browser'

mixpanel.init(process.env.VUE_APP_MIXPANEL ?? 'DUMMY', {
    debug: process.env.NODE_ENV === 'development',
    api_host: 'https://mp.spo-plus.com',
    ignore_dnt: true
});

Vue.config.productionTip = false
Vue.use(require('vue-shortkey'))
Vue.use(BootstrapVue);
Vue.use(Storage, {
    name: 'ls',
    namespace: 'jaba__'
});
Vue.use(vmodal)
Vue.use(VueSweetalert2)
Vue.use(vueMoment, {
    moment
})
moment.locale('ja')
Vue.prototype.$md = new MobileDetect(navigator.userAgent);
Vue.prototype.$log = function (event, data) {
    mixpanel.track(event, data)
    window.gtag('event', event, data)
}

const baseURL = process.env.VUE_APP_API_URL ? process.env.VUE_APP_API_URL : '/api/'
Vue.prototype.$http = axios.create({
    baseURL: baseURL,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        post: {
            'Content-Type': 'application/json'
        }
    }
});

Vue.prototype.$baseUrl = baseURL

Vue.prototype.$copy = function(object){
    return JSON.parse(JSON.stringify(object))
}

Vue.prototype.$env = function() {
    return process.env
}

Vue.prototype.$http.interceptors.response.use(function (response) {
    // Do something with response data
    return response;
}, function (error) {
    if (error.response) {
        if (error.response.status && error.response.status === 503) {
            document.querySelector('#maintenance').style.display = 'block';
        }
        /*
        switch (error.response.status) {
          case 404:
            Vue.snotify.error('指定した映像が存在しない');
            break;
          case 403:
            Vue.snotify.error('復元する権限がありません');
            break;
          default:
            if (error.response.data && error.response.data.error) {
              Vue.snotify.error(error.response.data.error);
            } else {
              Vue.snotify.error('サーバーエラー');
            }
            break;
        }
         */
    } else if (error.request) {
        console.log(error.request);
        Vue.prototype.$snotify.error(error.message)
    } else {
        Vue.prototype.$snotify.error(error.message)
        console.log(error.config);
    }
    return Promise.reject(error);
});

let guid = Vue.ls.get('guid');
if (!guid) {
    import('@fingerprintjs/fingerprintjs').then(module => {
        module.default.load().then(fp => {
            return fp.get()
        }).then(r => {
            guid = r.visitorId
            Vue.ls.set('guid', guid);
        })
    })
}

Vue.prototype.$loggedIn = function () {
    return Vue.ls.get('user', null) !== null && Vue.ls.get('token', null) !== null;
}

Vue.prototype.$guid = function () {
    if (!guid && Vue.ls.get('guid')) {
        return Vue.ls.get('guid');
    }
    return guid;
}

/*
const options = {
  toast: {
    position: SnotifyPosition.rightTop,
    timeout: 4000
  }
}
*/

Vue.prototype.$toast = Vue.swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 4000
});

Vue.prototype.$getComponent = function (componentName) {
    let component = null
    let parent = this.$parent
    while (parent && !component) {
        if (parent.$options.name === componentName) {
            component = parent
        }
        parent = parent.$parent
    }
    return component
}

Vue.prototype.$snotify = {
    error (text, title) {
        Vue.prototype.$toast.fire({
            'type': 'error',
            text,
            title
        })
    },
    warning (text, title) {
        Vue.prototype.$toast.fire({
            'type': 'warning',
            text,
            title
        })
    },
    info (text, title) {
        Vue.prototype.$toast.fire({
            'type': 'info',
            text,
            title
        })
    },
    success (text, title) {
        Vue.prototype.$toast.fire({
            'type': 'success',
            text,
            title
        })
    }
}

router.afterEach((to) => {
    mixpanel.track_pageview({
        page: to.path,
        title: document.title
    })

    window.gtag( 'page_view', {
        page_title: document.title,
        page_path: to.path
    })
})

// Vue.use(Snotify, options)

if (process.env.NODE_ENV !== 'development') {
    Vue.prototype.$ab = new Notifier({
        projectId: 322672,
        projectKey: 'ed4478782c5a32340c4aa5f2e7aebd09',
        environment: process.env.NODE_ENV
    });
    Vue.prototype.$ab.addFilter((notice) => {
        if (Vue.prototype.$loggedIn()) {
            notice.session = {
                username: Vue.ls.get('user', null)
            };
        }
        notice.context.version = process.env.VUE_APP_VERSION;
        return notice;
    });
    Vue.config.errorHandler = function (err, vm, info) {
        Vue.prototype.$ab.notify({
            error: err,
            params: {info: info}
        });
    }
    // window.addEventListener('error', event => {
    //     Vue.prototype.$ab.notify({
    //         error: event.error,
    //         params: {event: event}
    //     });
    // });
    // window.addEventListener('unhandledrejection', event => {
    //     Vue.prototype.$ab.notify({
    //         error: event.reason,
    //         params: {event: event}
    //     });
    // });
}

/* eslint-disable no-new */
new Vue({
    el: '#app',
    router,
    render: h => h(App),
    created () {
        let t = this.$ls.get('token');
        if (t) {
            this.$http.defaults.headers['Authorization'] = 'Token ' + t
        }
        const csrftoken = getCookie('csrftoken');
        if (csrftoken) {
            this.$http.defaults.headers['X-CSRFToken'] = csrftoken
        }
    }
})


function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}
